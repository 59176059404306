.about-bg {
    background-color: #ec343b;
    margin-bottom: 1rem;
}

.about-bg .abouttitle h2{
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 45px;
    padding: 40px 0;
    font-weight: 500;
    text-align: center;
}

.abouts {
	display: flow-root;
	background: #fff;
}

.abouts-us {
	text-align: justify;
}

.abouts .abouts-bg .abouts-us h3 {
	font-size: 40px;
	line-height: 72px;
	font-weight: 500;
	margin-bottom: 0px;
	padding: 40px 0px;
	text-transform: uppercase;
}
.abouts-us p {
	line-height: 29px;
	font-size: 16px;
	font-weight: 400;
	color: #000;
}
.abouts .abouts-bg .abouts-us figure {
    margin: 0px;
    padding-top: 40px;
}
.abouts .abouts-bg .abouts-us figure img {
    width: 100%;
}
.abouts .abouts-bg {
	margin: 0;
	padding-left: 0;
}

.cur {
	color: #ad2100;
	font-weight: 500;
}

.value-section {
	padding: 7em 0;
}

.heading-value h2 {
	font-size: 34px;
	font-weight: 700;
}

.values-2{
	width: 100%;
}

.values-2:nth-child(1){
	background: rgba(0, 0, 0, 0.05);
	font-weight: 400;
}

.values-2:nth-child(1) .text p{
	color: rgba(0, 0, 0, 0.5);
}

.values-2:nth-child(2){
	background: #fda729;
}

.values-2:nth-child(3){
	background: black;
}

.values-2:nth-child(3) .text{
	color: rgba(255, 255, 255, 0.8);
}


.values-2:nth-child(3) .text h3{
	color: rgba(255, 255, 255, 0.8);
}

.values-2:nth-child(4){
	background: rgba(0, 0, 0, 0.05);
}

.values-2:nth-child(4) .text p{
	color: rgba(0, 0, 0, 0.5);
}

.values-2:nth-child(5){
	background: #fda729;
}

.values-2:nth-child(6){
	background: black;
}
.values-2:nth-child(6) .text{
	color: rgba(255, 255, 255, 0.8);
}
.values-2:nth-child(6) .text h3{
	color: rgba(255, 255, 255, 0.8);
}
.values-2 .text{
	margin-left: 2em;
}

.values-2 h3{
	font-size: 20px;
	font-weight: 700;
}

.more-info{
	padding: 4em 0;
}

.more-info .text {
	color: black;
}
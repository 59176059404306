footer {
    border-radius: 0 !important;
    border-bottom: 0;
    font-size: 16px;
    background: #141313;
    color: #ffffff;
}

.footer_header {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
}


.footer_company_name {
    color: #ec343b;
}



ul#footer_list li{
    display: inline-block !important;
}

.social_icon {
    height: 3rem;
    width: 3rem;
    display: block;
    float: left;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    position: relative;
}

.social_icon img {
    height: 3rem;
    width: 3rem;
    position: relative;
    border-radius: 50%;
}

.text-align-center {
    text-align: center;
}


.carousel_company_name {
    font-size: 40px;
    color: #ec343b;
    line-height: 1.3;
    font-weight: 900;
    text-transform: uppercase;
}

.overlay {
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    opacity: .6;
}

.subheading {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
}

.company-section {
    padding: 1em 1em;
}

.categorySection {
    /* padding: 1em 0; */
    z-index: 0;
}

.categoryTitle{
    color: #fff;
    position: relative;
    top: 20%;
    text-align: center;
}

.text-white{
    color: #fff;
}

.products_section {
    padding: 3em 1em; 
    align-items: center;
    text-align: center;
    background: linear-gradient(to bottom, #ec343b, #5dbcd2)
}

.products_subhaeding{
    font-weight: 800;
    font-size: 18px;
    display: block;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.3);
    position: relative;
}

.reach_us {
    position: relative;
    text-align: left;
    width: 75%;
    padding: 2.5em 7em;
}

.align-items-center {
    text-align: center;
}

@media (min-width: 768px){
    .overlay {
        width: 50%;
    }
    .carousel_company_name{
        font-size: 40px;
    }    
}

@media ( max-width: 768px){

    .carousel_company_name{
        font-size: 16px;
    }    
    .subheading {
        font-size: 16px;
    }
    
    .products_subhaeding{
        font-weight: 800;
        font-size: 16px;
    }
}

.category-bg {
    background-color: #ec343b;
    margin-bottom: 1rem;
}

.category-bg .categorytitle h2{
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 45px;
    padding: 40px 0;
    font-weight: 500;
    text-align: center;
}

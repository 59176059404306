.contact-bg {
    background-color: #ec343b;
    margin-bottom: 1rem;
}

.contact-bg .contacttitle h2{
    color: #fff;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 45px;
    padding: 40px 0;
    font-weight: 500;
    text-align: center;
}

.contacts {
	display: flow-root;
	background: #fff;
}
.contacts .contacts-bg .contacts-us h3 {
	font-size: 40px;
	line-height: 72px;
	font-weight: 500;
	margin-bottom: 0px;
	padding: 40px 0px;
	text-transform: uppercase;
}
.contacts-us p {
	line-height: 29px;
	font-size: 16px;
	font-weight: 400;
	color: #000;
}
.contacts .contacts-bg .contacts-us figure {
    margin: 0px;
    padding-top: 40px;
}
.contacts .contacts-bg .contacts-us figure img {
    width: 50%;
}
.contacts .contacts-bg {
	margin: 0;
	padding-left: 0;
}

.cur {
	color: #ad2100;
	font-weight: 500;
}

.contact-section .contact-info p a {
    color: #485c68; 
}

.contact-section .box {
    width: 100%;
    display: block; 
}

.icon {
  width: 3rem;
  height: 3rem;
  background: #ec343b;
  border-radius: 50%;
}

@media (max-width: 767.98px) {
  .icon {
    width: 40px;
    height: 40px;
    background: #fda729;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; 
  }
  .icon span {
    color: #fff; 
  }
  .text {
    width: calc(100% - 40px);
    padding-left: 15px; 
  }
}

.text {
  width: calc(100%-40px);
  color: rgb(255, 255, 255, 0.7);
  padding-left: 0.45rem;
}

.btn:not(:disabled):not(.disabled){
  cursor: pointer;
}

.btn.btn-primary{
  background: #ec343b;
  border: 1px solid #ec343b;
  color: #000000;
}

.ftco-navbar-light{
  background: #1683fb;
  z-index: 3;
  padding: 0;
}

.navbar-toggler:not(:disabled):not(.disabled){
  cursor: pointer;
}

.ftco-navbar-light .navbar-toggler {
  border: none;
  color: rgba(255, 255, 255, 0.5) !important;
  padding-right: 0;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: .1em;
}

.ftco-navbar-light .navbar-nav > .nav-item.active > a {
  color: #ec343b;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  line-height: 1;
  background-color: transparent;
  border-radius: 0.25rem;
}

.oi {
  position: relative;
  top: 1px;
  display: inline-block;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smooting: antialized;
}

.primary-btn{
  color: #f05c68;
}